:root {
  --main-color: #045C5C;
  --primary-color: #045C5C;
  --secondary-color: #04847C;
  --tertiary-color: #8B95AB;
  --active-color: #03A69A;
  --container-color: #F5FAFF;
  --background-color: #F5F6F8;
  --dark-color: #2C2400;
  --light-color: #FEFEFE;
}