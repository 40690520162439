.loading-text:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}
  
  @keyframes dots {
    0%, 20% {
      color: var(--primary-color);
      text-shadow:
        .25em 0 0 var(--primary-color),
        .5em 0 0 var(--primary-color);}
    40% {
      color: transparent;
      text-shadow:
        .25em 0 0 var(--primary-color),
        .5em 0 0 var(--primary-color);}
    60% {
      text-shadow:
        .25em 0 0 transparent,
        .5em 0 0 var(--primary-color);}
    80%, 100% {
      text-shadow:
        .25em 0 0 transparent,
        .5em 0 0 transparent;}}
  
  