.sidenav {
  position: relative;
  left: -25vw;
  transition: left 0.4s ease-in-out;
  background: #fff;
  border-radius: 10px;
}
.sidenav-toggle {
  position: absolute;
  top: 50vh;
  right: -3%;
  transform: scale(1.5);
  z-index: 2;
  border-radius: 50%;
  width: 50;
  height: 50;
  /* outline: 3px solid yellow; */
  transition: all 0.4s ease-in-out;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 3.6px;
  display: 'flex';
  align-items: center;
  justify-content: center;
}
.sidenav-toggle:hover{
  transform: scale(1.6);
  cursor: pointer;
}
.sidenav:hover{
  position: relative;
  left: -24vw;
  transition: left 0.4s ease-in-out;
  background: #fff;
  border-radius: 10px;
}
.sidenav.open {
  left: 0;
}
.content.open {
  left: 15vw;
}
