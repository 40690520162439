.sidebar-container {
    overflow: scroll;
    height: 100px;
  }

  .wrapper-horizontal {
    display: flex;
    overflow-x: auto;
  }
  .wrapper-horizontal::-webkit-scrollbar {
    display: none;
  }
  .wrapper-horizontal {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .sidebar-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .sidebar-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  
  .sidebar-container .img-container {
    width: 90px;
    height: 90px;
    background-size: cover;
    background-origin: border-box;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .sidebar-container .img-container:hover {
    transform: scale(1.1);
    transform-origin: 50% 50%;
  }
  
  .sidebar-container .video-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-origin: border-box;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .sidebar-container .video-img svg {
    fill: #ffffff;
    opacity: 0.75;
    font-size: 82px;
    transition: all 0.2s ease-in-out;
  }
  
  .sidebar-container .video-img:hover svg {
    opacity: 1;
  }
  
  .sidebar-container .video-container .video-description {
    padding: 10px 8px;
    background: #ffffff;
    border-radius: 5px;
  }
  
  .MuiTabs-indicator {
    background-color: var(--primary-color) !important;
    height: 4px !important;
    border-radius: 1px !important;
  }
  
  .MuiTab-root {
    font-family: "Poppins", "sans-serif" !important;
    border-bottom: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
  
  .Mui-selected {
    color: var(--primary-color) !important;
    font-weight: 700;
  }
  
  .main-img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.2s ease-in-out;
  }
  
  .main-img svg {
    fill: #ffffff;
    opacity: 0.75;
    font-size: 120px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .main-img svg:hover {
    opacity: 1;
  }
  
  .inner-border {
    box-shadow: inset 0px 0px 0px 5px rgb(30, 52, 101);
    box-sizing: border-box;
  }
  