.dropzone .cancel-button {
    font-size: 14px; 
    margin-left: 6px;
    color: #ADB5BD;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.dropzone .cancel-button:hover {
    color: #ED3C58;
}

.text-header {
    font-size: 12px;
    color: #212121;
}

.select-button {
    border: 2px solid #A5A5A5;
    background: #E9E9E9;
    font-family: Poppins;
}